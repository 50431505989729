import React from 'react';
import { Gate } from './gate';
import { numGates } from './gateData';

export function GateList(props) {
    let gates = [];
    
    // Go through each gate and generate the select element for it
    for (let gateNum=1; gateNum <= numGates; gateNum++) {
        let gateKey = 'gate_' + gateNum;

        gates[gateNum] = (
            <Gate 
                key={gateKey} 
                gateNumber={gateNum} 
                handleChange={props.makeHandler(gateNum)}
                currentValue={props.currentGateState[gateKey]}
            />
        );
    }

    return (
        <fieldset className="shard-select-container">
            <legend key="gateListLegend">Enlightenment Gates</legend>
            {gates}
        </fieldset>
    );
}
