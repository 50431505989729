import React, {useState, useEffect} from 'react';

import { GateList } from './gateList';
import { numGates, minStepsForNextGate, gateShardCosts, maxGateSteps } from './gateData';

function gateKey(gateNum) { 
    return `gate_${gateNum}`;
}

export function ShardCalculator(props) {
    const [totalShards, setTotalShards] = useState(0);
    
    // Create an object which sets all valid gates to the specified value
    function setGatesToValue(value) {
        let intValue = parseInt(value);
        
        let gateValues = {}
        for (let key in gateShardCosts) {
            gateValues[key] = intValue;
        }
        
        return gateValues;
    }

    const [gateValues, setGateValues] = useState( setGatesToValue(0) );

    // Handle change to gate value
    function makeHandler(gateNum) {
        function updater(target, prevState) {
            let target_value = parseInt(target.value);
            let new_gate_vals = { 
                ...prevState, 
                [gateKey(gateNum)]: target_value
            };
            
            // If any previous gates are below the min steps, raise them to the min steps
            for (let i = 1; i < gateNum; i++) {
                if (new_gate_vals[gateKey(i)] < minStepsForNextGate) {
                    new_gate_vals[gateKey(i)] = minStepsForNextGate;
                }
            }
            
            // If the value is lower than the min steps for next gate, set all later gates to 0
            if (target_value < minStepsForNextGate) {
                for (let i = gateNum + 1; i <= numGates; i++) {
                    new_gate_vals[gateKey(i)] = 0;
                }
            }
            
            return new_gate_vals;
        }

        return event => setGateValues(prevState => updater(event.target, prevState));
    }

    // Set all gates to max
    function maxAllGates() {
        setGateValues( setGatesToValue(maxGateSteps) );
    }

    // Set all gates to 0
    function clearAllGates() {
        setGateValues( setGatesToValue(0) );
    }
  
    // Calculate the total shard cost for the gate configuration
    function calculateTotal() {
        let total = 0;
        let tempValue = 0;
        for (let key in gateValues) {
            tempValue = parseInt(gateValues[key]);
            if (tempValue <= 0) { continue; }
            // Loop through gate shard costs until you have reached the max gate level specified
            for (let i = 0; i < gateShardCosts[key].length; i++) {
                if (i >= tempValue) { break; }
                total += gateShardCosts[key][i];
            }
        }
    
        setTotalShards(total);
    }
    
    useEffect( () => {
        calculateTotal();
    })

    return (
        <div id="shard-calculator">
            <div key="calculator-container" id="calculator-container">
                <GateList key="gate_list" makeHandler={makeHandler} currentGateState={gateValues} />
                <section className="total-shards-container" key="total_shards">
                    <h2 key="section-label">Total Shards</h2>
                    <p key="section-value" id="total-shards-display" data-testid="total-shards-display" aria-live="polite">{totalShards}</p>
                </section>
            </div>
            <div className="gate-options" key="gate_options">
                <button id="max_all_gates" key="max_all" onClick={maxAllGates}>Max All Gates</button>
                <button id="clear_all_gates" key="clear_all" onClick={clearAllGates}>Clear All Gates</button>
            </div>
        </div>
    );
}
