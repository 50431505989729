import React from 'react';
import { maxGateSteps } from './gateData';

export function Gate(props) {
    // Generate the option menu for the gate steps
    let opts = [];
    for (let i=0; i<= maxGateSteps; i++) {
        opts[i] = <option value={i} key={i}>{i}</option>;
    }
    
    // Determine the gate number and relevant key from the props
    let gateNum = parseInt(props.gateNumber);
    let gateKey = 'gateSelect' + gateNum;
    
    return (
        <div className="gate-container">
            <label key={gateKey + 'Label'} htmlFor={gateKey}>{props.gateNumber}</label>
            <select 
                key={gateKey} 
                data-gateid={gateNum} 
                id={gateKey} 
                onChange={props.handleChange} 
                value={props.currentValue}
            >
                {opts}
            </select>
        </div>
    );
}
